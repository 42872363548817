import React, { useState, useEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const DeviceDetector = () => {
  const appLink = isIOS
    ? 'programamais://home'
    : 'intent://home#Intent;scheme=programamais;package=com.fermento.programamais;end';
  const downloadLink = isIOS
    ? 'https://apps.apple.com/br/app/sindilegis-mais/id1480766309'
    : 'https://play.google.com/store/apps/details?id=com.fermento.programamais';
  
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenApp = () => {
    window.location.href = appLink;

    const startTime = Date.now();
    const timer = setTimeout(() => {
      if (Date.now() - startTime < 1500) { 
        window.location.href = downloadLink;
      }
      setOpen(false);
    }, 2000);
    
    return () => clearTimeout(timer);
  };

  const handleDownloadApp = () => {
    window.location.href = downloadLink;
    setOpen(false);
  };

  const handleStayOnWeb = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="dialog-title">Deseja abrir o app?</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="dialog-description"
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
        >
          Você pode abrir o app do mais, baixar caso ainda não tenha ou{' '}
          <span
            onClick={handleStayOnWeb}
            style={{ color: '#38817f', textDecoration: 'underline', cursor: 'pointer' }}
            role="button"
            tabIndex={0}
            aria-label="Permanecer no navegador"
          >
            permanecer no navegador.
          </span>
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOpenApp} color="primary">
          Abrir o App
        </Button>
        <Button
          onClick={handleDownloadApp}
          style={{ color: '#38817f' }}
        >
          Baixar o App
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeviceDetector;
